import React from "react";
import MainLayout from "src/layouts/Main";
import PageTitleHero from "components/PageTitleHero";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { navigate } from "gatsby-link";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default () => {
  const [state, setState] = React.useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    console.log("submitted");

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
    // navigate(form.getAttribute("action"));
  };

  return (
    <MainLayout>
      <PageTitleHero title="Contact <span>Me</span>" class="contact-hero" />

      <div className="flex-content-container">
        <div className="contact-form-container">
        <iframe id="contact-form" src="https://docs.google.com/forms/d/e/1FAIpQLSdZTTDr38VhbB4vLO5VGB7HSmZ8xaK7r8ehK2OlHUMet8KtIA/viewform?embedded=true" width="100%" height="100%" frameBorder="0">Loading…</iframe>
        </div>
      </div>
    </MainLayout>
  );
};
